import Vue from "vue";

export default {
  state: {
    studies: [],
  },
  mutations: {
    SET_STUDIES(state, studies) {
      state.studies = studies;
    },
    ADD_STUDY(state, study) {
      state.studies.push(study);
    },
    UPDATE_STUDY(state, study) {
      const index = state.studies.findIndex((s) => s.id === study.id);
      Vue.set(state.studies, index, study);
    },
    DELETE_STUDY(state, id) {
      state.studies = state.studies.filter((s) => s.id !== id);
    },
  },
  actions: {},
};

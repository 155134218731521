import Vue from "vue";

export default {
  state: {
    groups: [],
  },
  mutations: {
    SET_GROUPS(state, groups) {
      state.groups = groups;
    },
    ADD_GROUP(state, group) {
      state.groups.push(group);
    },
    UPDATE_GROUP(state, group) {
      const index = state.groups.findIndex((g) => g.id === group.id);
      Vue.set(state.groups, index, group);
    },
    DELETE_GROUP(state, id) {
      state.groups = state.groups.filter((g) => g.id !== id);
    },
  },
  actions: {},
};

import Vue from "vue";

export default {
  state: {
    diseases: [],
  },
  mutations: {
    SET_DISEASES(state, diseases) {
      state.diseases = diseases;
    },
    ADD_DISEASE(state, disease) {
      state.diseases.push(disease);
    },
    UPDATE_DISEASE(state, disease) {
      const index = state.diseases.findIndex((d) => d.id === disease.id);
      Vue.set(state.diseases, index, disease);
    },
    DELETE_DISEASE(state, id) {
      state.diseases = state.diseases.filter((d) => d.id !== id);
    },
  },
  actions: {},
};
